import React, { FormEvent, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  verifyPasswordResetCode,
  confirmPasswordReset,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { ReactComponent as Logo } from "../../theme/images/logo.svg";
import image from "../../theme/images/decal.png";
import { auth } from "../../firebase";
import { OVERVIEW_ROUTE, RESET_PASSWORD_ROUTE } from "../../Routes";

export const ResetPasswordAction: React.FC = () => {
  const [newPassword, setNewPassword] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [ooobCode, setoOoobCode] = useState("");
  const [ooobCodeError, setOoobCodeError] = useState("");
  const [confirmPasswordResetError, setConfirmPasswordResetError] =
    useState("");
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    const ooobCode = searchParams.get("oobCode") || "";
    if (!ooobCode && ooobCode.length > 0)
      setOoobCodeError("No 'oobCode' found");
    setoOoobCode(ooobCode);
    verifyPasswordResetCode(auth, ooobCode)
      .then((email: string) => setUserEmail(email))
      .catch((error) => setOoobCodeError(error.message))
      .finally(() => setIsInitialLoading(false));
  }, [searchParams]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsFormLoading(true);

    try {
      await confirmPasswordReset(auth, ooobCode, newPassword);
      await signInWithEmailAndPassword(auth, userEmail, newPassword);

      // after login navigate to overview
      navigate(OVERVIEW_ROUTE);
    } catch (e: any) {
      setConfirmPasswordResetError(e.message);
    } finally {
      setIsFormLoading(false);
    }
  };

  if (isInitialLoading) {
    return (
      <div className="container min-vh-100 align-items-center">
        <div
          className="position-absolute top-50 start-50 spinner-border"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container min-vh-100">
      <div className="row min-vh-100 align-items-center">
        <div className="col-12 col-lg-6 offset-lg-3">
          <div className="login bg-white my-40 shadow">
            <div className="login__decal">
              <img src={image} className="img-fluid" alt="" />
            </div>

            <div className="login__wrapper py-30 px-60">
              <div className="login__logo text-center mb-60">
                <Logo />
              </div>

              <div className="login__title text-center">Set new password</div>

              {!ooobCodeError && (
                <>
                  <div className="login__text text-center px-lg-60 mb-40">
                    enter your new password for {userEmail}
                  </div>

                  <form
                    className="login__form"
                    onSubmit={(e) => handleSubmit(e)}
                  >
                    <div className="mb-20">
                      <label htmlFor="new-password" className="visually-hidden">
                        New password
                      </label>
                      <input
                        id="new-password"
                        type="password"
                        name="new-password"
                        className="form-control"
                        placeholder="New password"
                        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                        title="at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                        required={true}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </div>

                    <button type="submit" className="btn btn-primary w-100">
                      {isFormLoading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Send"
                      )}
                    </button>
                  </form>
                </>
              )}

              {/* error */}
              {(ooobCodeError || confirmPasswordResetError) && (
                <div className="login__text text-center px-lg-60 mb-40">
                  <p>
                    Your request to reset your password has expired or the link
                    has already been used.
                  </p>
                  <p>
                    <Link to={RESET_PASSWORD_ROUTE}>Try again.</Link>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
