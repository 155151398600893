import React, { FormEvent, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, Link } from "react-router-dom";
import { auth, sendPasswordReset } from "../firebase";
import { LOGIN_ROUTE } from "../Routes";
import image from "../theme/images/decal.png";
import { ReactComponent as Logo } from "../theme/images/logo.svg";
import "./Login.scss";

export const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState("");
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/");
  }, [user, loading, navigate]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setResetPasswordError("");
    setIsFormLoading(true);

    try {
      await sendPasswordReset(email);

      alert("Password reset link sent!");
    } catch (e: any) {
      setResetPasswordError(e.message);
    } finally {
      setIsFormLoading(false);
    }
  };

  if (loading)
    return (
      <div className="container min-vh-100 align-items-center">
        <div
          className="position-absolute top-50 start-50 spinner-border"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  return (
    <div className="container min-vh-100">
      <div className="row min-vh-100 align-items-center">
        <div className="col-12 col-lg-6 offset-lg-3">
          <div className="login bg-white my-40 shadow">
            <div className="login__decal">
              <img src={image} className="img-fluid" alt="" />
            </div>

            <div className="login__wrapper py-30 px-60">
              <div className="login__logo text-center mb-60">
                <Logo />
              </div>

              <div className="login__title text-center">Forgot password</div>

              <div className="login__text text-center px-lg-60 mb-40">
                enter your email and we'll send you a link to reset your
                password
              </div>

              <form className="login__form" onSubmit={(e) => handleSubmit(e)}>
                <div className="mb-20">
                  <label htmlFor="email" className="visually-hidden">
                    Email address
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email address"
                    required={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="mb-40">
                  <Link
                    className="text-decoration-none text-secondary"
                    to={LOGIN_ROUTE}
                  >
                    Back to login
                  </Link>
                </div>

                <div className="mb-40">{resetPasswordError}</div>

                <button type="submit" className="btn btn-primary w-100">
                  {isFormLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Send"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
