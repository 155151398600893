import React, { FormEvent, useEffect, useState } from "react";
import { logEvent } from "firebase/analytics";
import image from "../theme/images/decal.png";
import { ReactComponent as Logo } from "../theme/images/logo.svg";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { analytics, auth, logInWithEmailAndPassword } from "../firebase";
import { RESET_PASSWORD_ROUTE } from "../Routes";
import "./Login.scss";

export const Login: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/");
  }, [user, loading, navigate]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsFormLoading(true);
    setLoginError("");

    try {
      await logInWithEmailAndPassword(email, password);
    } catch (e: any) {
      setLoginError(e.message);
    } finally {
      logEvent(analytics, "user_logged_in");
      setIsFormLoading(false);
    }
  };

  if (loading)
    return (
      <div className="container min-vh-100 align-items-center">
        <div
          className="position-absolute top-50 start-50 spinner-border"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  return (
    <div className="container min-vh-100">
      <div className="row min-vh-100 align-items-center">
        <div className="col-12 col-lg-6 offset-lg-3">
          <div className="login bg-white my-40 shadow">
            <div className="login__decal">
              <img src={image} className="img-fluid" alt="" />
            </div>

            <div className="login__wrapper py-30 px-60">
              <div className="login__logo text-center mb-60">
                <Logo />
              </div>

              <div className="login__title text-center">Welcome</div>

              <div className="login__text text-center px-lg-60 mb-40">
                log in to continue to mabriteccentral.com
              </div>

              <form className="login__form" onSubmit={(e) => handleSubmit(e)}>
                <div className="mb-20">
                  <label htmlFor="email" className="visually-hidden">
                    Email address
                  </label>
                  <input
                    id="email"
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder="Email address"
                    required={true}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className="mb-15">
                  <label htmlFor="password" className="visually-hidden">
                    Password
                  </label>
                  <input
                    id="password"
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    required={true}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div className="mb-40">
                  <Link
                    className="text-decoration-none text-secondary"
                    to={RESET_PASSWORD_ROUTE}
                  >
                    Forgot password?
                  </Link>
                </div>

                <div className="mb-40">{loginError}</div>

                <button type="submit" className="btn btn-primary w-100">
                  {isFormLoading ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Continue"
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
