import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../../firebase";
import { Licence, UserData } from "../../typings";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation as faTriangleExclamationLight } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ApiKeyGenerator } from "../../components/ApiKeyGenerator";
import {useHasRole} from "../../hooks/useHasRole";
import {RoleProtectedWrapper} from "../../components/auth/RoleProtectedWrapper";

export const Account: React.FC = () => {
  const [user, loading] = useAuthState(auth);
  const [userData, setUserData] = useState<UserData | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [licences, setLicences] = useState<Licence[] | undefined>(undefined);
  const [isLicenceLoading, setIsLicenceLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loading) return;
    if (!user) return;

    setIsLicenceLoading(true);

    const collectionRef = collection(db, "users", user.uid, "licences");
    const q = query(collectionRef, orderBy("expiresAt", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.docs.length === 0) return;
      let newLicences: Licence[] = [];

      // accumulate credits
      querySnapshot.docs.forEach((licence) => {
        const data = licence.data() as Licence;
        newLicences.push(data);
      });

      setLicences(newLicences);
      setIsLicenceLoading(false);
    });

    return () => unsubscribe();
  }, [loading, user]);

  useEffect(() => {
    if (loading) return;
    if (!user) return;

    const docRef = doc(db, "users", user.uid);

    getDoc(docRef).then((docSnap) => {
      if (!docSnap.exists()) return setError("User not found");
      setUserData(docSnap.data() as UserData);
    });
  }, [user, loading]);

  // check if one of the licences expiresAt is greater than Date.now() and credits > 0
  const hasActiveLicences =
    licences &&
    licences.some(
      (licence) =>
        licence.expiresAt.toMillis() > Date.now() && licence.credits > 0
    );

  // loading state
  if (loading || isLicenceLoading) {
    return (
      <div className="container min-vh-100 align-items-center">
        <div
          className="position-absolute top-50 start-50 spinner-border text-primary"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error && userData) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <FontAwesomeIcon icon={faTriangleExclamationLight as IconProp} />{" "}
            {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4 mb-30">
          <div className="h3">Account</div>
          <div className="text-body mb-20">
            <div className="fw-bolder mb-10">User</div>
            <div>{userData?.company}</div>
            <div>
              {userData?.firstName} {userData?.lastName}
            </div>
            <div>{user?.email}</div>
          </div>

          <div className="text-body mb-20">
            <div className="fw-bolder mb-10">Address</div>
            <div>{userData?.address.street1}</div>
            <div>{userData?.address.street2}</div>
            <div>
              {userData?.address.zip}, {userData?.address.city}
            </div>
            <div>{userData?.address.country}</div>
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <p className="h3">Licences</p>
          {!hasActiveLicences && (
            <p>
              Your licence has expired. To continue using our services order a
              new licence.{" "}
              <a
                href="https://mabriteccentral.com/order"
                rel="noreferrer"
                target="_blank"
                className="link-primary"
              >
                order now
              </a>
              .
            </p>
          )}
          <div className="d-none d-lg-block order-list-results__header bg-primary py-20 px-30">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-3">
                    <strong className="text-black">Credits</strong>
                  </div>
                  <div className="col-3">
                    <strong className="text-black">Status</strong>
                  </div>
                  <div className="col-3">
                    <strong className="text-black">Expiry date</strong>
                  </div>
                  <div className="col-3">
                    <strong className="text-black">Type</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {licences &&
            licences.map(({ credits, expiresAt, type }, i) => (
              <div className="col-12" key={i}>
                <div className="order-list-results__wrapper bg-white">
                  <div className="row gy-20">
                    <div className="col-12">
                      <div className="d-flex flex-column justify-content-center h-100">
                        <div className="row">
                          <div className="col-12 col-sm-6 col-lg-3">
                            <span>
                              <span className="d-block d-lg-none fw-bold">
                                Credits:
                              </span>
                              {credits}
                            </span>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-3">
                            <span>
                              <span className="d-block d-lg-none fw-bold">
                                Status:
                              </span>
                              {expiresAt.toMillis() > Date.now()
                                ? "active"
                                : "expired"}
                            </span>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-3">
                            <span>
                              <span className="d-block d-lg-none fw-bold">
                                Expiry date:
                              </span>
                              {new Date(
                                expiresAt.seconds * 1000
                              ).toLocaleString()}
                            </span>
                          </div>
                          <div className="col-12 col-sm-6 col-lg-3">
                            <span>
                              <span className="d-block d-lg-none fw-bold">
                                Type:
                              </span>
                              {type}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <RoleProtectedWrapper role="developer">
            <div className="col-12 col-lg-8 offset-lg-4">
                <div className="h3">API Key</div>
                <ApiKeyGenerator/>
            </div>
        </RoleProtectedWrapper>
      </div>
    </div>
  );
};
