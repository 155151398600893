import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./Card.scss";

type Props = {
  title: string;
  icon: IconProp;
  text: string;
  linkTo: string;
  isExternal?: boolean;
};

export const Card: React.FC<Props> = ({
  title,
  text,
  linkTo,
  icon,
  isExternal = false,
}) => {
  return (
    <div className="card shadow bg-white">
      <div className="card__header d-flex p-30">
        <div className="card__header-title">
          <div className="fw-bold text-secondary">{title}</div>
        </div>
      </div>

      <div className="card__image mt-5">
        <i className="fa-light fa-book-open-cover" />
        <FontAwesomeIcon icon={icon} />
      </div>

      <div className="card__body p-20 pb-30">
        <p className="mb-40">{text}</p>

        <div className="card__body-link">
          {isExternal ? (
            <a href={linkTo} target="_blank" rel="noreferrer">
              {title}
            </a>
          ) : (
            <Link to={linkTo}>{title}</Link>
          )}
        </div>
      </div>
    </div>
  );
};
