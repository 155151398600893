import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebase";
import { LOGIN_ROUTE } from "../../Routes";
import { TrialHeader } from "../../components/TrialHeader";

export const Authenticated: React.FC = () => {
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;

    if (!user) navigate(LOGIN_ROUTE);
  }, [user, loading, navigate]);

  if (loading)
    return (
      <div className="container min-vh-100 align-items-center">
        <div
          className="position-absolute top-50 start-50 spinner-border text-primary"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );

  return (
    <div>
      <TrialHeader />
      <Header />
      <main className="min-vh-100 py-80">
        <Outlet />
      </main>
    </div>
  );
};
