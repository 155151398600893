import React from "react";
import { useActiveLicence } from "../hooks/useActiveLicence";

export const TrialHeader: React.FC = () => {
  const [hasActiveLicence, daysLeft, isTrial, isLoading] = useActiveLicence();

  if (isLoading) return null;
  if (!isTrial) return null;
  if (hasActiveLicence) {
    return (
      <div className="h-25 bg-dark p-20 text-center">
        You have {daysLeft} days left with your trial licence. To continue using
        our services upgrade to a commercial license.{" "}
        <a
          href="https://mabriteccentral.com/order"
          rel="noreferrer"
          className="link-primary"
        >
          upgrade now
        </a>
      </div>
    );
  }

  return (
    <div className="h-25 p-20 bg-dark text-center">
      Your licence has expired. To continue using our services order a new
      licence.{" "}
      <a
        href="https://mabriteccentral.com/order"
        rel="noreferrer"
        className="link-primary"
      >
        order now
      </a>
    </div>
  );
};
