import React, { useEffect, useState } from "react";
import { Order, Spectra, UserData } from "../../typings";
import { useAuthState } from "react-firebase-hooks/auth";
import { analytics, auth, db } from "../../firebase";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { logEvent } from "firebase/analytics";
import { SPECTRA_QUALITY_ORDER } from "../../Routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTriangleExclamation as faTriangleExclamationLight } from "@fortawesome/pro-light-svg-icons";
import { CSVLink } from "react-csv";
import { SpectraRowHeader } from "../../components/SpectraRowHeader";
import {
  NON_QUALIFIED_RESULT_HINT_MESSAGE,
  QUALIFIED_RESULT_HINT_MESSAGE,
} from "../../Constants";
import { ReactComponent as DownloadSvg } from "../../theme/images/download.svg";
import { isSpectraQualityOrder } from "../../utils/is-spectra-quality-order";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { IdentificationReport } from "../../pdf/IdentificationReport";
import { SpectraRow } from "../../components/SpectraRow";

export const IdentificationOrder: React.FC = () => {
  const [order, setOrder] = useState<Order | undefined>(undefined);
  const [isOrderLoading, setIsOrderLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [spectras, setSpectras] = useState<Spectra[]>([]);
  const [user, loading] = useAuthState(auth);
  const [userData, setUserData] = useState<UserData | undefined>(undefined);
  const { orderId } = useParams();
  const navigate = useNavigate();

  // redirect to sepctra quality order
  useEffect(() => {
    if (loading) return;
    if (!user) return;
    if (!order) return;

    if (isSpectraQualityOrder(order.database))
      navigate(generatePath(SPECTRA_QUALITY_ORDER, { orderId: order.orderId }));
  });

  useEffect(() => {
    if (loading) return;
    if (!user) return;

    const collectionRef = collection(db, "spectras");
    const q = query(
      collectionRef,
      where("userId", "==", user.uid),
      where("orderId", "==", orderId),
      orderBy("sample", "asc"),
      orderBy("file", "asc")
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      // reset orders when update come in
      const newSpectras: Spectra[] = [];
      querySnapshot.forEach((doc) => {
        // update OrdersList
        const data: Spectra = doc.data() as Spectra;
        newSpectras.push({ ...data, id: doc.id });
      });

      setSpectras(newSpectras);
    });

    return () => unsubscribe();
  }, [user, loading, orderId]);

  useEffect(() => {
    if (loading) return;
    if (!user) return;

    const collectionRef = collection(db, "orders");
    const q = query(
      collectionRef,
      where("userId", "==", user.uid),
      where("orderId", "==", orderId)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.size === 0) setError(`Order not found.`);
      querySnapshot.forEach((doc) => {
        // update OrdersList
        setOrder(doc.data() as Order);
      });

      setIsOrderLoading(false);
    });

    return () => unsubscribe();
  }, [user, loading, orderId]);

  useEffect(() => {
    if (loading) return;
    if (!user) return;

    const docRef = doc(db, "users", user.uid);

    getDoc(docRef).then((docSnap) => {
      if (!docSnap.exists()) return setError("User not found");
      setUserData(docSnap.data() as UserData);
    });
  }, [user, loading]);

  const csvData = [
    [
      "orderId",
      "customerReference",
      "file",
      "sample",
      "qualifiedScore",
      "qualifiedResult",
      "nonQualifiedScore",
      "nonQualifiedResult",
    ],
    ...spectras.map((spectra) => [
      spectra.orderId,
      order?.customerReference,
      spectra.file,
      spectra.sample,
      spectra.score,
      spectra.result,
      spectra.nonQualifiedScore,
      spectra.nonQualifiedResult,
    ]),
  ];

  // todo make it not jump
  const progressBarWidth = Math.min(
    Math.max(
      Math.floor(
        (spectras.length /
          (order?.spectraCount && order?.spectraCount > 0
            ? order?.spectraCount + 5
            : order?.files.length || 5)) *
          100
      ),
      2
    ),
    100
  );
  const progressBar = (
    <div className="progress w-100">
      <div
        className="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuenow={progressBarWidth}
        aria-valuemin={0}
        aria-valuemax={order?.spectraCount}
        style={{ width: `${progressBarWidth}%` }}
      />
    </div>
  );

  // loading state
  if (isOrderLoading) {
    return (
      <div className="container min-vh-100 align-items-center">
        <div
          className="position-absolute top-50 start-50 spinner-border text-primary"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error && !order) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <FontAwesomeIcon icon={faTriangleExclamationLight as IconProp} />{" "}
            {error}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6 mb-40 mb-lg-80">
          {order?.customerReference && (
            <div>
              <span>
                Customer reference: <strong>{order?.customerReference}</strong>
              </span>
            </div>
          )}
          <div>
            <span>
              Order ID: <strong>{order?.orderId}</strong>
            </span>
          </div>
          <div>
            <span>
              Order date:{" "}
              <strong>
                {order &&
                  new Date(order?.uploadedAt?.seconds * 1000).toLocaleString()}
              </strong>
            </span>
          </div>
          <div>
            <span>
              Number of spectras uploaded:{" "}
              <strong>{order?.spectraCount}</strong>
            </span>
          </div>
          <div>
            <span>
              Database used: <strong>{order?.database}</strong>
            </span>
          </div>
        </div>
        <div className="col-12 col-lg-6 mb-40 mb-lg-80">
          <div className="d-flex justify-content-lg-end gap-30 downloads-wrapper">
            {order?.status === "DONE" && (
              <CSVLink
                data={csvData}
                filename={`report_${order.orderId}.csv`}
                className=""
                onClick={() => {
                  logEvent(analytics, "user_requested_csv");
                }}
              >
                <DownloadSvg />
                <span>CSV export</span>
              </CSVLink>
            )}

            {order?.status === "DONE" && userData && spectras.length < 500 && (
              <PDFDownloadLink
                document={
                  <IdentificationReport
                    spectras={spectras}
                    order={order}
                    userData={userData}
                  />
                }
                fileName={`report_${order?.orderId}.pdf`}
              >
                {({ blob, url, loading, error }) => {
                  if (error) window.alert("Failed to generate the PDF.");

                  return (
                    <span>
                      {loading ? (
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <DownloadSvg />
                      )}
                      <span>PDF export</span>
                    </span>
                  );
                }}
              </PDFDownloadLink>
            )}
          </div>
        </div>

        {/* Order error */}
        {order?.error && (
          <div className="col-12 mb-40">
            <span>
              {" "}
              <FontAwesomeIcon
                icon={faTriangleExclamationLight as IconProp}
              />{" "}
              Something went wrong while processing the Files.
            </span>
          </div>
        )}

        <div className="col-12 mb-40">
          {order?.status === "RUNNING" && progressBar}
        </div>
        <div className="col-12">
          {spectras.length > 0 && <SpectraRowHeader />}
        </div>
        {spectras.length > 0 &&
          spectras.map((spectra, index) => (
            <SpectraRow {...spectra} key={index} />
          ))}
        {spectras.length > 0 && (
          <div className="col-8 offset-2 text-center">
            <div>{QUALIFIED_RESULT_HINT_MESSAGE}</div>
            <div>{NON_QUALIFIED_RESULT_HINT_MESSAGE}</div>
          </div>
        )}
      </div>
    </div>
  );
};
