import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { zoom } from "d3-zoom";
import {
  ZoomTransform,
  scaleLinear,
  select,
  axisLeft,
  axisBottom,
  Selection,
} from "d3";
import useResizeObserver from "../hooks/useRezizeObserver";
import {throttle} from "lodash";

type Props = {
  masses: { int: number; mz: number }[];
  matchedProfile?: {
    mw: number;
    subunit: string;
    matched?: boolean;
  }[];
};

const MARGIN = { top: 30, right: 30, bottom: 50, left: 60 };

const getEmptyzones = (masses: { int: number; mz: number }[]) => {
  let emptyzones: { start: number; end: number }[] = [];
  // add last peak to get emptyzone to 20'000
  const newMasses = [...masses, { mz: 20000, int: 0 }];

  newMasses
    .sort((a, b) => a.mz - b.mz)
    .forEach(({ mz }, i) => {
      if (
        i + 1 !== newMasses.length &&
        Math.abs(mz - newMasses[i + 1].mz) > 800
      ) {
        emptyzones.push({ start: mz, end: newMasses[i + 1].mz });
      }
    });

  return emptyzones;
};

const getHighestInt = ({ masses }: Props) =>
  Math.max(...masses.map(({ int }) => int));

export const SpectrumPlot: React.FC<Props> = (props) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const dimensions = useResizeObserver(wrapperRef);
  const [currentZoomState, setCurrentZoomState] = useState<ZoomTransform>();

  const { width = 0, height = 0 } = dimensions! || { width: 0, height: 0 }; // || wrapperRef !== null && wrapperRef.current!.getBoundingClientRect();

  const boundsWidth = width - MARGIN.right - MARGIN.left;
  const boundsHeight = height - MARGIN.top - MARGIN.bottom;

  // will be called initially and on every data change
  useEffect(() => {
    if (!svgRef.current) return;
    const svg = select(svgRef.current);
    const svgContent = svg.select(".content");

    // scales + line generator
    const xScale = scaleLinear().domain([4000, 20000]).range([0, boundsWidth]);

    const visiblePeaks = props.masses.filter(
        ({ mz }) => mz >= xScale.domain()[0] && mz <= xScale.domain()[1]
    );

    const visibleSubunits = props.matchedProfile?.filter(
        ({ mw }) => mw >= xScale.domain()[0] && mw <= xScale.domain()[1]
    ) || [];

    if (currentZoomState) {
      const newXScale = currentZoomState.rescaleX(xScale);
      xScale.domain(newXScale.domain());
    }

    const highestInt = getHighestInt(props);
    const yScaleMax = highestInt;

    const yScale = scaleLinear()
      .domain([0, yScaleMax])
      .range([boundsHeight, 0]);

    // remove old peaks on dimension change
    svgContent.selectAll("*").remove();


    // emptyzones
    props.masses.length > 0 &&
      svgContent
        .selectAll(".emptyzones")
        .data(getEmptyzones(props.masses))
        .enter()
        .append("line")
        .attr("x1", ({ start }) => Math.round(xScale(start + 5)))
        .attr("x2", ({ end }) => Math.round(xScale(end - 5)))
        .attr("y1", yScale(0))
        .attr("y2", yScale(0))
        .attr("stroke", "red")
        .attr("stroke-width", 4)
        .attr("data-start", ({ start }) => start)
        .attr("data-end", ({ end }) => end)
        .on("mouseover", (event) => {
          const tooltipDiv = tooltipRef.current;
          select(event.currentTarget).attr("stroke", "#d3df00");
          if (tooltipDiv)
            return d3
              .select(tooltipDiv)
              .style("opacity", "1")
              .style("visibility", "visible");
        })
        .on("mousemove", (event) => {
          const tooltipDiv = tooltipRef.current;
          if (tooltipDiv) {
            const target = event.target as SVGLineElement;
            return d3
              .select(tooltipDiv)
              .style("top", event.pageY - 30 + "px")
              .style("left", event.pageX + 10 + "px")
              .html(
                `emptyzone from ${Number(target.dataset.start).toLocaleString(
                  "de-CH"
                )} m/z to ${Number(target.dataset.end).toLocaleString(
                  "de-CH"
                )} m/z`
              );
          }
        })
        .on("mouseleave", (event) => {
          const tooltipDiv = tooltipRef.current;
          d3.select(event.currentTarget).attr("stroke", "red");
          if (tooltipDiv)
            return d3
              .select(tooltipDiv)
              .style("opacity", "0")
              .style("visibility", "hidden");
        });

    props.matchedProfile &&
      svgContent
        .selectAll(".matchedProfilePeaks")
        .data(visibleSubunits.filter(({ mw }) => mw < 20000))
        .enter()
        .append("line")
        .attr("x1", ({ mw }) => Math.round(xScale(mw)))
        .attr("x2", ({ mw }) => Math.round(xScale(mw)))
        .attr("y1", yScale(yScaleMax))
        .attr("y2", yScale(0))
        .attr("stroke", ({ matched }) => (matched ? "red" : "grey"))
        .attr("stroke-width", 8)
        .attr("opacity", ({ matched }) => (matched ? 0.5 : 0.2))
        .attr("data-subunit", ({ subunit }) => subunit)
        .on("mouseover", (event) => {
          const tooltipDiv = tooltipRef.current;
          select(event.currentTarget)
            .attr("stroke", "#d3df00")
            .attr("opacity", 1);
          if (tooltipDiv)
            return d3
              .select(tooltipDiv)
              .style("opacity", "1")
              .style("visibility", "visible");
        })
        .on("mousemove", (event) => {
          const tooltipDiv = tooltipRef.current;
          if (tooltipDiv) {
            const target = event.target as SVGLineElement;
            return d3
              .select(tooltipDiv)
              .style("top", event.pageY - 30 + "px")
              .style("left", event.pageX + 10 + "px")
              .html(`${target.dataset.subunit}`);
          }
        })
        .on("mouseleave", (event) => {
          const tooltipDiv = tooltipRef.current;
          d3.select<SVGElement, {
            mw: number;
            subunit: string;
            matched?: boolean;
          }>(event.currentTarget)
            .attr("stroke", ({ matched }) => (matched ? "red" : "grey"))
            .attr("opacity", ({ matched }) => (matched ? 0.5 : 0.2));
          if (tooltipDiv)
            return d3
              .select(tooltipDiv)
              .style("opacity", "0")
              .style("visibility", "hidden");
        });

    // spectrum peaks
    props.masses.length > 0 &&
      svgContent
        .selectAll(".allPeaks")
        .data(visiblePeaks.filter(({ mz }) => mz > 4000))
        .enter()
        .append("line")
        .attr("x1", ({ mz }) => Math.round(xScale(mz)))
        .attr("x2", ({ mz }) => Math.round(xScale(mz)))
        .attr("y1", ({ int }) => Math.round(yScale(int)))
        .attr("y2", yScale(0))
        .attr("stroke", "#282c32")
        .attr("stroke-width", 3)
        .attr("data-mz", ({ mz }) => mz)
        .attr("data-int", ({ int }) => int)
        .on("mouseover", (event) => {
          const tooltipDiv = tooltipRef.current;
          select(event.currentTarget).attr("stroke", "#d3df00");
          // sort peaks to show hovered peak on top
          svgContent.selectAll("*").sort((a: any) => {
            if (a.mz !== parseFloat(event.currentTarget.dataset.mz)) return -1;
            else return 1;
          });
          if (tooltipDiv)
            return d3
              .select(tooltipDiv)
              .style("opacity", "1")
              .style("visibility", "visible");
        })
        .on("mousemove", (event) => {
          const tooltipDiv = tooltipRef.current;
          if (tooltipDiv) {
            const target = event.target as SVGLineElement;
            return d3
              .select(tooltipDiv)
              .style("top", event.pageY - 30 + "px")
              .style("left", event.pageX + 10 + "px")
              .html(
                `m/z: ${Number(target.dataset.mz).toLocaleString(
                  "de-CH"
                )} | intensity: ${Number(target.dataset.int).toLocaleString(
                  "de-CH"
                )}`
              );
          }
        })
        .on("mouseleave", (event) => {
          const tooltipDiv = tooltipRef.current;
          d3.select(event.currentTarget).attr("stroke", "#282c32");
          if (tooltipDiv)
            return d3
              .select(tooltipDiv)
              .style("opacity", "0")
              .style("visibility", "hidden");
        });

    // axes
    const xAxis = axisBottom(xScale);

    svg
      .select<SVGSVGElement>(".x-axis")
      .attr("transform", `translate(0, ${boundsHeight})`)
      .call(xAxis);

    const yAxis = axisLeft(yScale);
    svg.select<SVGSVGElement>(".y-axis").call(yAxis);

    // zoom
    const zoomBehavior = zoom()
      .scaleExtent([1, 40])
      .translateExtent([
        [0, 0],
        [width, height],
      ])
      .on("zoom", throttle((event: { transform: any; currentTarget: any; }) => {
        const zoomState = event.transform;
        setCurrentZoomState(zoomState);

        // remove tooltip
        const tooltipDiv = tooltipRef.current;
        d3.select(event.currentTarget).attr("stroke", "#282c32");
        if (tooltipDiv)
          return d3
            .select(tooltipDiv)
            .style("opacity", "0")
            .style("visibility", "hidden");
      }, 10));

    // todo clen up?
    svg.call((selection) =>
      zoomBehavior(
        selection as unknown as Selection<Element, unknown, any, any>
      )
    );
  }, [
    currentZoomState,
    props,
    dimensions,
    boundsWidth,
    boundsHeight,
    height,
    width,
  ]);

  return (
    <div ref={wrapperRef} style={{ marginBottom: "2rem", height: "400px" }}>
      <div
        className="tooltip"
        ref={tooltipRef}
        style={{ background: "white", padding: "2px 5px" }}
      />
      <svg
        ref={svgRef}
        width={width}
        height={height}
        style={{ display: "inline-block" }}
      >
        <defs>
          <clipPath id="spectrum-plot">
            <rect x="0" y="0" width="100%" height="100%" />
          </clipPath>
        </defs>
        <g
          className="content"
          clipPath={`url(#spectrum-plot`}
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        />
        <g
          width={boundsWidth}
          height={boundsHeight}
          transform={`translate(${[MARGIN.left, MARGIN.top].join(",")})`}
        >
          <g className="x-axis" />
          <g className="y-axis" />
        </g>
      </svg>
    </div>
  );
};
