import React from "react";
import { Link } from "react-router-dom";
import { OVERVIEW_ROUTE } from "../Routes";

export const NotFound: React.FC = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 offset-lg-2 col-lg-8">
          <div className="h1 text-center">404</div>
          <div className="h6 text-center">
            Ooops, page not fount. Return to{" "}
            <Link to={OVERVIEW_ROUTE}>home</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
