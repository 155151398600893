import React, { useEffect } from "react";
import { auth, db, generateApiKey } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, getDoc, deleteField, updateDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation as faTriangleExclamationLight } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const ApiKeyGenerator = () => {
  const [apiKey, setApiKey] = React.useState(
    "XXXXXXXX-XXX-XXXX-XXXXX-XXXXXXXXXXXX"
  );
  const [getApiKeyLoading, setGetApiKeyLoading] = React.useState(false);
  const [hasApiKeyLoading, setHasApiKeyLoading] = React.useState(false);
  const [user, loading] = useAuthState(auth);
  const [showApiKey, setShowApiKey] = React.useState(false);
  const [hasApiKey, setHasApiKey] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);

  // check if user has "apiKeyHash"
  useEffect(() => {
    if (loading) return;
    if (!user) return;
    setHasApiKeyLoading(true);

    const docRef = doc(db, "users", user.uid);

    getDoc(docRef)
      .then((docSnap) => {
        setGetApiKeyLoading(false);
        if (!docSnap.exists()) return setError("User not found");
        if (docSnap.data()?.apiKeyHash) {
          setHasApiKey(true);
        }
      })
      .finally(() => {
        setHasApiKeyLoading(false);
      });
  }, [user, loading]);

  // copy apiKey to clipboard
  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(apiKey).then(() => {
      alert("API Key copied to clipboard");
    });
  };

  const handleDeleteApiKey = async () => {
    if (!user) return;
    if (
      !window.confirm(
        "Are you sure you want to delete your API key?\nYour old API will be invalid!"
      )
    )
      return;
    const docRef = doc(db, "users", user.uid);

    try {
      await updateDoc(docRef, {
        apiKeyHash: deleteField(),
      });

      setApiKey("");
      setHasApiKey(false);
      setShowApiKey(false);
    } catch (error) {
      console.error("Error deleting API key", error);
      setError("Failed to delete API key");
    }
  };

  const handleGenerateApiKey = async () => {
    setGetApiKeyLoading(true);
    try {
      const { data } = await generateApiKey();
      const newApiKey = data.apiKey;
      setApiKey(newApiKey);
      setHasApiKey(true);
      setShowApiKey(true);
    } catch (error) {
      console.error("Error generating API key", error);
      setError("Failed to generate API key");
    } finally {
      setGetApiKeyLoading(false);
    }
  };

  // loading state
  if (loading || hasApiKeyLoading) {
    return (
      <div className="container min-vh-100 align-items-center">
        <div
          className="position-absolute top-50 start-50 spinner-border text-primary"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  // no api key generated
  if (!hasApiKey) {
    return (
      <div className="">
        <button
          type="button"
          className="btn btn-primary mb-10"
          onClick={handleGenerateApiKey}
          disabled={getApiKeyLoading}
        >
          {getApiKeyLoading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Generate API Key"
          )}
        </button>
        {error && (
          <div>
            <FontAwesomeIcon icon={faTriangleExclamationLight as IconProp} />{" "}
            {error}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-md-12 mb-20">
        <input
          type={showApiKey ? "text" : "password"}
          className="form-control"
          id="inputPassword"
          value={apiKey}
          readOnly={true}
        />
        {showApiKey && (
          <p className="text-danger">
            For security reasons will only appear once. Copy it now!
          </p>
        )}
      </div>
      <div className="d-flex gap-30 mb-20">
        <button
          className="btn btn-primary"
          onClick={handleCopyApiKey}
          disabled={!showApiKey}
        >
          Copy API Key
        </button>
        <button
          className="btn btn-outline-primary"
          onClick={handleDeleteApiKey}
        >
          Delete API Key
        </button>
      </div>
    </div>
  );
};
