import { v4 as uuidv4 } from "uuid";
import { auth, startMosquitoEggOrder, uploadFiles } from "./index";
import { MachineType } from "../typings";
import { getMachineType } from "../utils/get-machine-type";

export const createMosquitoVectorOrder = async (
  machineType: MachineType,
  files: File[],
  customerReference?: string
) => {
  const machine = getMachineType(machineType);

  if (!machine) throw new Error("No machine found for: " + machineType);
  const user = auth.currentUser;
  if (!user) throw new Error("no user");
  const orderId = uuidv4();

  const filePaths = (await uploadFiles(files, orderId)) as string[];
  console.log("files uploaded: ", filePaths, customerReference);
  return startMosquitoEggOrder({
    files: filePaths,
    orderId,
    machineType: machine,
    customerReference: customerReference ?? false,
  });
};
