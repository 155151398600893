import React from "react";
import "./Footer.scss";

export const Footer: React.FC = () => {
  return (
    <div className="site-sub-footer bg-dark pb-30">
      <div className="container">
        <div className="row gy-10 py-30">
          <div className="col-12 col-lg-6">
            <div className="navbar navbar-expand navbar-dark sub-footer-navigation">
              <ul id="menu-footer" className="navbar-nav">
                <li
                  itemScope={true}
                  itemType="https://www.schema.org/SiteNavigationElement"
                  className="nav-item"
                >
                  <a
                    title="Sitemap"
                    href="https://mabriteccentral.com/price-and-license/"
                    className="nav-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Price and License
                  </a>
                </li>
                <li
                  itemScope={true}
                  itemType="https://www.schema.org/SiteNavigationElement"
                  className="nav-item"
                >
                  <a
                    title="Impressum"
                    href="https://mabriteccentral.com/legal-notice/"
                    className="nav-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Legal notice
                  </a>
                </li>
                <li
                  itemScope={true}
                  itemType="https://www.schema.org/SiteNavigationElement"
                  className="nav-item"
                >
                  <a
                    title="Impressum"
                    href="https://mabriteccentral.com/privacy/"
                    className="nav-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy
                  </a>
                </li>
                <li
                  itemScope={true}
                  itemType="https://www.schema.org/SiteNavigationElement"
                  className="nav-item"
                >
                  <a
                    title="Impressum"
                    href="https://mabriteccentral.com/agb/"
                    className="nav-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Service
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 col-lg-6 text-lg-end" id="copyright">
            <span className="text-primary">mabritec</span> - gesellschaft für
            massenbasierte rasche identifikationstechnologien
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="border-bottom border-5 border-primary" />
          </div>
        </div>
      </div>
    </div>
  );
};
