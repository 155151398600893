import React from "react";
import {
  faBookOpenCover,
  faArrowUpFromSquare,
  faList,
  faBrowser,
} from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Card } from "../components/Card";
import { IDENTIFICATION_UPLOAD, ORDERS_ROUTE, TUTORIAL_ROUTE } from "../Routes";

export const Overview: React.FC = () => {
  return (
    <div className="container">
      <div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 gy-40">
        <div className="col">
          <Card
            title="Tutorial"
            text="A quick introduction to our platform, including an upload guide for both supported MALDI-TOF formats"
            linkTo={TUTORIAL_ROUTE}
            isExternal={true}
            icon={faBookOpenCover as IconProp}
          />
        </div>
        <div className="col">
          <Card
            title="Identification"
            text="Easy access tool to upload your spectra for comparison with our mabritecCentral database"
            linkTo={IDENTIFICATION_UPLOAD}
            icon={faArrowUpFromSquare as IconProp}
          />
        </div>
        <div className="col">
          <Card
            title="Orders"
            text="A complete list of all your uploaded spectra and the results returned from the database matching"
            linkTo={ORDERS_ROUTE}
            icon={faList as IconProp}
          />
        </div>
        <div className="col">
          <Card
            title="Website"
            text="Find out more about the mabritecCentral database and the team of experts responsible for continually updating it"
            linkTo="https://mabriteccentral.com/"
            isExternal={true}
            icon={faBrowser as IconProp}
          />
        </div>
      </div>
    </div>
  );
};
