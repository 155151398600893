import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Tooltip } from "bootstrap";
import {
  SPECTRA_QUALITY_HINT_MESSAGE,
  SPECTRA_QUALITY_IDENTIFICATION_HINT_MESSAGE,
} from "../Constants";

export const SpectraQualityRowHeader: React.FC = () => {
  const resultToolTipRef = useRef(null);
  const nonQualifiedResultRef = useRef(null);

  useEffect(() => {
    if (!resultToolTipRef.current) return;
    if (!nonQualifiedResultRef.current) return;

    new Tooltip(resultToolTipRef.current, {
      title: SPECTRA_QUALITY_IDENTIFICATION_HINT_MESSAGE,
      placement: "top",
      trigger: "hover",
    });

    new Tooltip(nonQualifiedResultRef.current, {
      title: SPECTRA_QUALITY_HINT_MESSAGE,
      placement: "top",
      trigger: "hover",
    });
  });

  return (
    <div className="d-none d-lg-block overview-results__header bg-primary py-20 px-30">
      <div className="row">
        <div className="col-3">
          <strong className="text-black">Filename</strong>
        </div>
        <div className="col-3">
          <strong className="text-black">Sample Name</strong>
        </div>
        <div className="col-1">
          <strong className="text-black">Score</strong>
        </div>
        <div className="col-3">
          <strong className="text-black">
            Identification{" "}
            <FontAwesomeIcon
              icon={faInfoCircle as IconProp}
              ref={resultToolTipRef}
            />
          </strong>
        </div>
        <div className="col-2">
          <strong className="text-black">
            Spectra quality{" "}
            <FontAwesomeIcon
              icon={faInfoCircle as IconProp}
              ref={nonQualifiedResultRef}
            />
          </strong>
        </div>
      </div>
    </div>
  );
};
