export const QUALIFIED_RESULT_HINT_MESSAGE =
  "Qualified results are automatically interpreted and have a specificity of 99%.";
export const NON_QUALIFIED_RESULT_HINT_MESSAGE =
  "Non-Qualified results are automatically interpreted. Yellow indicates a specificity of 80% - 99%, Red < 80%.";

export const SPECTRA_QUALITY_IDENTIFICATION_HINT_MESSAGE =
  "Identification result of E.coli DSM 6897 displayed as Qualified (green), Non-Qualified (orange) or none (red).";

export const SPECTRA_QUALITY_HINT_MESSAGE =
  "Spectra quality examination result (excellent, good, moderate, poor).";
