import { MachineType } from "../typings";

const machineTypeOptions: { [K in MachineType]: string } = {
  bruker: "bruker",
  vitekMSMzml: "vitekMS",
  shimadzuAscii: "axima",
  vitekMSAscii: "axima",
  clover: "clover",
};

export const getMachineType = (machineType: MachineType): string => {
  return machineTypeOptions[machineType];
};
