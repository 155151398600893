import { ReactElement } from "react";

export const getSpectraWarning = (
  databaseUsed: string
): ReactElement | false => {
  if (!config.hasOwnProperty(databaseUsed)) return false;

  // get string from config object
  return config[databaseUsed];
};

const config: { [databaseUsed: string]: ReactElement } = {
  "Bacillus-cereus-group": (
    <>
      Isolates of the Bacillus cereus group complex are difficult to
      discriminate by use of the «smear» method and the HCCA Matrix.
      <br />
      Best results are achieved with the «Bead-Beating» method and the
      Sinapicacid Matrix.
    </>
  ),
  Listeria: (
    <>
      Isolates of the Listeria “sensu stricto” clade are difficult to
      discriminate by use of the «smear» method and the HCCA Matrix.
      <br />
      Best results are achieved with the «Bead-Beating» method and the HCCA
      Matrix.
    </>
  ),
  "Enterobacter-cloacae-complex": (
    <>
      Isolates of the Enterobacter cloacae complex are difficult to discriminate
      by use of the «smear» method and the HCCA Matrix.
      <br />
      Best results are achieved with the «smear» method and the Sinapicacid
      Matrix.
    </>
  ),
  "Escherichia-Shigella": (
    <>
      Isolates of the Escherichia / Shigella group are difficult to discriminate
      by use of the «smear» method and the HCCA Matrix.
      <br />
      Best results are achieved with the «smear» method and the Sinapicacid
      Matrix.
    </>
  ),
  "Acinetobacter-baumannii-complex": (
    <>
      Isolates of the Acinetobacter baumannii complex are difficult to
      discriminate by use of the «smear» method and the HCCA Matrix.
      <br />
      Best results are achieved with the «smear» method and the Sinapicacid
      Matrix.
    </>
  ),
  "Streptococcus-mitis-group": (
    <>
      Isolates of the Streptococcus mitis group are difficult to discriminate by
      use of the «smear» method and the HCCA Matrix.
      <br />
      Best results are achieved with the «extraction» method and the HCCA
      Matrix.
    </>
  ),
  Salmonella: (
    <>
      Isolates of the Genus Salmonella are difficult to discriminate by use of
      the «smear» method and the HCCA Matrix.
      <br />
      Best results are achieved with the «smear» method and the Sinapicacid
      Matrix.
    </>
  ),
  "Bordetella-pertussis-parapertussis-bronchiseptica": (
    <>
      Isolates of the Bordetella pertussis / parapertussis / bronchiseptica
      group are difficult to discriminate by use of the «smear» method and the
      HCCA Matrix.
      <br />
      Best results are achieved with the «extraction» method and the HCCA
      Matrix.
    </>
  ),
};
