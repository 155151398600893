import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import { useEffect, useState } from "react";
import {
  collection,
  onSnapshot,
  query,
  Timestamp,
} from "firebase/firestore";

export const useActiveLicence = (): [boolean, number, boolean, boolean] => {
  const [user, loading] = useAuthState(auth);
  const [isTrial, setIsTrial] = useState<boolean>(true);
  const [expiresAt, setExpiresAt] = useState<Timestamp>(Timestamp.now);
  const [hasActiveLicence, setHasActiveLicence] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (loading) return;
    if (!user) return;

    setIsLoading(true);

    const collectionRef = collection(db, "users", user.uid, "licences");
    const q = query(collectionRef);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.docs.length === 0) {
        setHasActiveLicence(false);
        setIsTrial(false);
        return;
      }

      if (
        querySnapshot.docs.filter((licence) => licence.data().type !== "trial")
          .length > 0
      )
        setIsTrial(false);

      // get latest expiry date
      let latestExpiry = Timestamp.now();
      querySnapshot.docs.forEach((licence) => {
        if (licence.data().expiresAt.toMillis() > latestExpiry.toMillis()) {
          latestExpiry = licence.data().expiresAt;
        }
      });
      setExpiresAt(latestExpiry);
      if (latestExpiry.toMillis() <= Date.now()) {
        setHasActiveLicence(false);
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [loading, user]);

  const daysLeft = Math.floor(
    (expiresAt.toMillis() - Date.now()) / (1000 * 60 * 60 * 24)
  );

  return [hasActiveLicence, daysLeft, isTrial, isLoading];
};
