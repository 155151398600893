import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";

export const LogCurrentPage: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    logEvent(analytics, "screen_view", {
      firebase_screen: pathname,
      firebase_screen_class: pathname,
    });
  }, [pathname]);

  return null;
};
