import React, { useEffect, useState } from "react";
import { MacoMatchDetailEntry, Spectra } from "../../typings";
import { useAuthState } from "react-firebase-hooks/auth";
import {auth, db, getProfile} from "../../firebase";
import { useParams, useSearchParams } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {SpectrumPlot} from "../../components/SpectrumPlot";
import {filterHIPeaks} from "../../utils/filter-hi-peaks";
import {getMatches} from "../../utils/get-matches";

export const MacoMatchDetail: React.FC = () => {
    const [spectra, setSpectra] = useState<Spectra | undefined>(undefined);
    const [isSpectraLoadig, setIsSpectraLoading] = useState<boolean>(true);
    const [profile, setProfile] = useState<{ mw: number; subunit: string, species_plus: string }[] | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(
        undefined
    );
    const [ppm, setPpm] = useState<number>(800);
    const [database, setDatabase] = useState<string | undefined>(undefined);
    const [numberHIPeaks, setNumberHIPeaks] = useState<number>(50);
    const [getHIPeaks, setGetHIPeaks] = useState<boolean>(false);
    const [user, loading] = useAuthState(auth);
    const { id, selectedProfile } = useParams();
    const [searchParams] = useSearchParams();

    // on first render set database, ppm from search params
    useEffect(() => {
        if (searchParams.has("database")) {
            setDatabase(searchParams.get("database") as string);
        }

        if (searchParams.has("ppm")) {
            setPpm(parseInt(searchParams.get("ppm") as string));
        }

        if (searchParams.has("hi")) {
            setNumberHIPeaks(parseInt(searchParams.get("hi") as string));
        }

        if (searchParams.has("getHI")) {
            setGetHIPeaks(searchParams.get("getHI") === "true");
        }
    }, []);

    // load profile
    useEffect(() => {
        if (selectedProfile && selectedProfile.length === 0) return;
        if (selectedProfile === undefined) return;
        if(database === undefined) return;

        getProfile({ profile: selectedProfile, database: database }).then(({ data }) => {
            setProfile(data);
        }).catch((error) => {
            setErrorMessage(error.message);
        })
    }, [database]);

    useEffect(() => {
        if (loading) return;
        if (!user) return;
        if (id === undefined) return;

        const unsubscribe = onSnapshot(doc(db, "spectras", id), (doc) => {
            const data: Spectra = doc.data() as Spectra;
            setSpectra({ ...data, id: doc.id });
            setIsSpectraLoading(false);
        });
        return () => unsubscribe();
    }, [user, loading, id]);

    if (isSpectraLoadig) {
        return (
            <div className="container min-vh-100 align-items-center">
                <div
                    className="position-absolute top-50 start-50 spinner-border text-primary"
                    role="status"
                >
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    if (spectra === undefined) {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <FontAwesomeIcon icon={faExclamationTriangle as IconProp} /> Spectra
                        with not found.
                    </div>
                </div>
            </div>
        );
    }

    let masses = Array.isArray(spectra.masses) ? spectra.masses: [];
    if(getHIPeaks) masses = filterHIPeaks(masses, numberHIPeaks);
    const matchData = getMatches(masses || [], ppm, profile || []);
    // count mz not null in matchDetailData
    const matches: number = matchData.filter((entry) => entry.mz !== null).length;

    const species = profile && profile?.length > 0 ? profile[0].species_plus : "";

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-lg-6 mb-10 mb-lg-10">
                    <div>
            <span>
              Filename: <strong>{spectra?.file}</strong>
            </span>
                    </div>
                    <div>
            <span>
              Sample: <strong>{spectra?.sample}</strong>
            </span>
                    </div>
                    <div>
            <span>
              Datacount:{" "}
                <strong>{spectra?.masses && spectra.masses.length}</strong>
            </span>
                    </div>
                    <div>
            <span>
              Matches: <strong>{matches > 0 ? matches : ""} {profile ? `/ ${profile.length}` : ""}</strong>
            </span>
                    </div>
                    <div>
            <span>
              Species: <strong>{species}</strong>
            </span>
                    </div>
                <div>
            <span>
              Matched Profile: <strong>{selectedProfile}</strong>
            </span>
                </div>
            </div>

            <form onSubmit={async (e) => {
                e.preventDefault()
            }}>
                <div className="col-12">
                            <div className="row mb-20">
                                <div className="col-4">
                                    <div className="input-group">
                                        <span className="input-group-text">PPM</span>
                                        <input
                                            type="number"
                                            className="form-control"
                                            min={0}
                                            value={ppm}
                                            step={50}
                                            onChange={(e) => setPpm(parseInt(e.target.value))}
                                        />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="input-group">
                                        <span className="input-group-text">HI</span>
                                        <input
                                            type="number"
                                            className="form-control"
                                            min={0}
                                            value={numberHIPeaks}
                                            step={10}
                                            onChange={(e) => setNumberHIPeaks(parseInt(e.target.value))}
                                        />
                                        <div className="input-group-text">
                                            <input
                                                type="checkbox"
                                                checked={getHIPeaks}
                                                onChange={(e) => setGetHIPeaks(e.target.checked)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 mb-20">
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={window.close}>
                                Close
                            </button>
                        </div>
                    </form>


                    {errorMessage && (<div className="col-12 text-center mt-10"><FontAwesomeIcon
                        icon={faExclamationTriangle as IconProp}/> {errorMessage}</div>)}


                    {spectra.masses && spectra.masses.length > 0 && (
                        <SpectrumPlot
                            masses={spectra.masses}
                            matchedProfile={matchData.map(({mw, subunit, mz}) => ({
                                mw,
                                subunit,
                                matched: mz !== null
                            }))}
                        />
                    )}
                </div>
            </div>
            );
            };
