import React, { HTMLProps, useCallback, useState } from "react";
import prettyBytes from "pretty-bytes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";
import JSZip from "jszip";
import { useDropzone } from "react-dropzone";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Modal from "react-modal";
import classNames from "classnames";

type BrukerSelectorProps = {
  importFile: (file: File) => void;
  closeModal: () => void;
  isOpen: boolean;
};

type ExperimentData = {
  sampleName: string;
  spot: string;
  path: string;
};

const IndeterminateCheckbox = ({
  indeterminate,
  className = "",
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) => {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + " cursor-pointer form-check-input"}
      {...rest}
    />
  );
};

export const BrukerSelector: React.FC<BrukerSelectorProps> = ({
  importFile,
  closeModal,
  isOpen,
}) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [experimentData, setExperimentData] = useState<ExperimentData[]>([]);
  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [error, setError] = useState("");

  const columnHelper = createColumnHelper<ExperimentData>();

  const disableSelector = experimentData.length === 0;

  const columns = React.useMemo(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              disabled: disableSelector,
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      columnHelper.accessor("sampleName", {
        header: "Sample Name",
        id: "sampleName",
      }),
      columnHelper.accessor("spot", {
        header: "Spot",
        id: "spot",
      }),
    ],
    [disableSelector]
  );

  const table = useReactTable({
    data: experimentData,
    columns,
    state: {
      rowSelection,
      globalFilter,
    },
    enableRowSelection: true, //enable row selection for all rows
    // enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    setError("");
    try {
      const zipFile = await JSZip.loadAsync(acceptedFiles[0]);
      const runInfoMappedFilesFilter = zipFile.filter((relativePath) =>
        RegExp(/\/runInfoMapped.json/).test(relativePath)
      );

      if (runInfoMappedFilesFilter.length === 0) {
        setError("No runInfoMapped.json file found in zip file");
        return;
      }

      const runInfoMappedFileText = await runInfoMappedFilesFilter[0].async(
        "text"
      );
      const runInfoMappedFileJson = JSON.parse(runInfoMappedFileText);

      const newExperimentData = runInfoMappedFileJson.Analytes.map(
        ({ AnalyteId, Spot, SubPath }: any) => ({
          sampleName: AnalyteId,
          spot: Spot,
          path: `spectra/${runInfoMappedFileJson.ProjectName}/${SubPath.replace(
            /\\/g,
            "/"
          )}`,
        })
      );

      setSelectedFile(acceptedFiles[0]);
      setExperimentData(newExperimentData);
    } catch (e) {
      console.error(e);
      setError("Error parsing zip file");
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "application/zip": [".zip"],
    },
  });

  const handleClear = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    setError("");
    setExperimentData([]);
    setRowSelection({});
    setSelectedFile(undefined);
  };

  const handleSearchClear = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setGlobalFilter("");
  };

  const handleImportFile = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const { rows } = table.getSelectedRowModel();

    if (!selectedFile) return setError("No file selected");
    if (rows.length === 0) return setError("No samples selected");

    // get all rows that are not selected
    const notSelectedRows = table
      .getCoreRowModel()
      .rows.filter((row) => !row.getIsSelected());

    // paths to be removed from zip file
    const pathsToRemove = notSelectedRows.map((row) => row.original.path);

    try {
      // remove paths from zip file
      const zipFile = await new JSZip().loadAsync(selectedFile);
      pathsToRemove.forEach((path) => zipFile.remove(path));

      //remove unused logs folder
      zipFile.remove("logs");

      // create new zip file
      const newZipFile = await zipFile.generateAsync({ type: "blob" });

      // get filename from original file without extension
      const filename = selectedFile.name.split(".")[0];

      // create file from blob
      const newFile = new File([newZipFile], `${filename}_selection.zip`, {
        type: "application/zip",
      });

      logEvent(analytics, "user_imported_bruker-experiment", {
        selectedSamples: rows.length,
      });
      importFile(newFile);
      setError("");
      setExperimentData([]);
      setRowSelection({});
      setSelectedFile(undefined);
    } catch (e) {
      console.error(e);
      setError("Error creating new zip file");
      logEvent(analytics, "user_imported_bruker_failed", {
        error: e,
      });
    }
  };

  // file size error
  const fileToBig = selectedFile && selectedFile.size > 30 * 1024 * 1024 && (
    <p>File exceed 30MB.</p>
  );

  // no spots selected error
  const noSpotsSelected = experimentData.length > 0 &&
    table.getSelectedRowModel().rows.length === 0 && (
      <p>No sample/spots selected.</p>
    );

  const disableSubmit =
    table.getSelectedRowModel().rows.length === 0 ||
    !selectedFile ||
    selectedFile.size > 30 * 1024 * 1024;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      preventScroll={true}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.6)",
        },
        content: {
          background: "transparent",
          border: "none",
        },
      }}
    >
      <div
        className="bruker-selector modal show fade"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Bruker Experiment Selector</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => closeModal()}
              ></button>
            </div>

            <div className="modal-body">
              <div className="container col-md-12">
                <div className="row">
                  <div className="col-12">
                    <div className="fw-bolder mb-20">
                      01. Select your experiment file (.zip)
                    </div>

                    <div className="dropzone" id="dropzone">
                      {selectedFile && (
                        <div className="dz-details">
                          <div className="dz-filename text-break">
                            <span>{selectedFile.name}</span>
                          </div>
                          <div className="dz-size text-nowrap">
                            {prettyBytes(selectedFile.size)}
                          </div>
                          <div
                            className="dz-remove"
                            onClick={(e) => handleClear(e)}
                          >
                            <FontAwesomeIcon icon={faTimes as IconProp} />
                          </div>
                        </div>
                      )}
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="dz-message">
                          <p>
                            <strong>
                              Drag and Drop a bruker compass experiment file
                              here,
                            </strong>
                            <br />
                            or click to select one from your computer
                          </p>
                          <p className="small">
                            (file size must not exceed 30MB)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-60">
                    <div className="fw-bolder mb-20">
                      02. Select samples / spots
                    </div>

                    <div className="hstack gap-10 mb-10">
                      <input
                        type="text"
                        disabled={disableSelector}
                        value={globalFilter ?? ""}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        className="form-control"
                        placeholder={
                          !disableSelector ? "Search all columns..." : ""
                        }
                      />

                      <button
                        type="button"
                        disabled={disableSelector || !globalFilter}
                        className="btn-close opacity-100"
                        onClick={(e) => handleSearchClear(e)}
                      ></button>

                      <div className="form-check">
                        {flexRender(
                          table.getHeaderGroups()[0].headers[0].column.columnDef
                            .header,
                          table.getHeaderGroups()[0].headers[0].getContext()
                        )}
                      </div>
                    </div>

                    <div className="row g-5">
                      {table.getRowModel().rows.map((row) => {
                        const cells = row.getVisibleCells();

                        // checked state of row
                        const checked = cells[0].row.getIsSelected();
                        return (
                          <div className="col-6 col-md-4 col-lg-3" key={row.id}>
                            <div
                              className={classNames(
                                "row g-0 border border-1 border-dark rounded p-5",
                                {
                                  "border-dark": !checked,
                                  "bg-primary": checked,
                                }
                              )}
                            >
                              <div className="col-2">
                                {flexRender(
                                  cells[0].column.columnDef.cell,
                                  cells[0].getContext()
                                )}
                              </div>
                              <div className="col-10 text-break">
                                {cells[1].getValue() as String}
                              </div>
                              <div className="col-10 offset-2 small">
                                {cells[2].getValue() as String}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    {/*<table className="mb-60">
                                        <thead>
                                        {table.getHeaderGroups().map(headerGroup => (
                                            <tr key={headerGroup.id}>
                                                {headerGroup.headers.map(header => (
                                                    <th key={header.id}>
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                        </thead>
                                        <tbody>
                                        {table.getRowModel().rows.map(row => (
                                            <tr key={row.id}>
                                                {row.getVisibleCells().map(cell => (
                                                    <td key={cell.id}>
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>*/}
                  </div>
                </div>

                <div className="text-danger mb-30">
                  {error}
                  {fileToBig}
                  {noSpotsSelected}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={(e) => handleClear(e)}
              >
                Clear
              </button>
              <button
                type="button"
                className="btn btn-primary"
                disabled={disableSubmit}
                onClick={(e) => handleImportFile(e)}
              >
                Import
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
