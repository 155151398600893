import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  // connectAuthEmulator,
} from "firebase/auth";
import {
  getStorage,
  ref,
  uploadBytes,
  connectStorageEmulator,
} from "firebase/storage";
import {
  getFirestore,
  collection,
  addDoc,
  connectFirestoreEmulator,
} from "firebase/firestore";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import { createOrder } from "./create-order";
import { createSpectraQualityOrder } from "./create-spectra-quality-order";
import { createMosquitoVectorOrder } from "./create-mosquito-vector-order";
import {MacoMatch, MacoMatchDetailEntry} from "../typings";
import internal from "node:stream";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAINE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, "europe-west6");

const analytics = getAnalytics(app);

const storage = getStorage(app);
// Setup for local local emulator

if (process.env.REACT_APP_EMULATORS === "TRUE") {
  connectStorageEmulator(storage, process.env.HOST || "localhost", 9199);
  //connectFirestoreEmulator(db, "localhost", 8080);
  connectFunctionsEmulator(functions, "localhost", 5001);
  //connectAuthEmulator(auth, "http://127.0.0.1:9099");
}

const logInWithEmailAndPassword = async (email: string, password: string) => {
  return await signInWithEmailAndPassword(auth, email, password);
};

const registerWithEmailAndPassword = async (
  name: string,
  email: string,
  password: string
) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err: any) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email: string) => {
  return await sendPasswordResetEmail(auth, email);
};
const logout = () => {
  signOut(auth).then(() => console.log("User logged out"));
};

const uploadFile = (file: File, orderId: string) => {
  return new Promise((resolve, reject) => {
    const storageRef = ref(storage, `${orderId}_${file.name}`);

    uploadBytes(storageRef, file)
      .then((snapshot) => {
        resolve(snapshot.metadata.fullPath);
      })
      .catch((err) => reject(err.message));
  });
};

const uploadFiles = (files: File[], orderId: string) => {
  return Promise.all(files.map((file) => uploadFile(file, orderId)));
};

const startOrder = httpsCallable<
  {
    files: string[];
    orderId: string;
    machineType: string;
    database: string;
    customerReference?: string | false;
  },
  string
>(functions, "startOrder");

const startSpectraQualityOrder = httpsCallable<
  { files: string[]; orderId: string; machineType: string },
  string
>(functions, "startSpectraQualityOrder");

const startMosquitoEggOrder = httpsCallable<
  {
    files: string[];
    orderId: string;
    machineType: string;
    customerReference?: string | false;
  },
  string
>(functions, "startMosquitoEggOrder");

const generateApiKey = httpsCallable<{}, { apiKey: string }>(
  functions,
  "generateApiKey"
);

const getMacoMatches = httpsCallable<
  {
    minMatches: number;
    limit: number;
    database: string;
    ppm: number;
    masses: { mz: number; int: number }[];
  },
  MacoMatch[]
>(functions, "getMacoMatches");

const getMacoMatchDetail = httpsCallable<
    {
      selectedProfile: string;
      database: string;
      ppm: number;
      masses: { mz: number; int: number }[];
    },
    MacoMatchDetailEntry[]
>(functions, "getMacoMatchDetail");

const getProfile = httpsCallable<{ profile: string, database: string }, { mw: number; subunit: string, species_plus: string }[]>(functions, "getProfile");

export {
  app,
  auth,
  db,
  functions,
  analytics,
  startOrder,
  startSpectraQualityOrder,
  startMosquitoEggOrder,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  uploadFiles,
  createOrder,
  createSpectraQualityOrder,
  createMosquitoVectorOrder,
  generateApiKey,
  getMacoMatches,
  getMacoMatchDetail,
    getProfile
};
